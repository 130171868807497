import { graphql } from 'gatsby';
import React from 'react';

import { getHref, getImage } from '../../../../utils/sanityTypes';

import HotelsCarousel from '../../HotelsCarousel';
import { HotelsCarouselItem } from '../../HotelsCarousel/types';
import { HotelSectionProps } from './types';

const HotelsSection: React.VFC<HotelSectionProps> = ({ cards }) => {
  return (
    <HotelsCarousel
      data={(cards ?? []).map<HotelsCarouselItem>((card) => {
        const { _key, title, description, price, to, icon, isDark } = card ?? {};
        const { amount, currency, unit } = price ?? {};

        return {
          key: _key ?? '',
          title: title ?? '',
          description: description ?? '',
          amount: amount ?? 0,
          unit: unit ?? '',
          to: getHref(to),
          icon: getImage(icon),
          currency,
          isDark,
        };
      })}
    />
  );
};

export const fragment = graphql`
  fragment HotelsSectionFragment on SanityHotelsSection {
    _key
    _type
    cards {
      _key
      title
      price {
        ...PriceFragment
      }
      description
      icon {
        ...ImageFragment
      }
      to {
        ...LinkFragment
      }
      isDark
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HotelsSection;
