import React from 'react';
import { ReactSVG } from 'react-svg';
import 'slick-carousel/slick/slick.css';
import Link from '../../atoms/Link';

import * as Styled from './styles';
import { HotelProps } from './types';

const Hotel: React.FC<HotelProps> = ({
  to,
  title,
  icon,
  description,
  amount,
  currency,
  unit,
  isDark,
}) => (
  <Styled.Hotel {...{ isDark }}>
    <Styled.HotelTitle variant="headingL">{title}</Styled.HotelTitle>
    <Styled.HotelIcon>
      {icon?.image?.images?.fallback?.src && icon.image.images.fallback.src.includes('.svg') && (
        <ReactSVG src={icon.image.images.fallback?.src} />
      )}
    </Styled.HotelIcon>
    <Styled.HotelPrice variant="headingM">
      {currency} {amount}/{unit}
    </Styled.HotelPrice>
    <Styled.HotelDescription variant="textL">{description}</Styled.HotelDescription>
    <Styled.HotelAction>
      <Link {...{ to }}>Take a look</Link>
    </Styled.HotelAction>
  </Styled.Hotel>
);

export default Hotel;
