import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';

import { HotelsCarouselProps } from './types';
import { settings } from './const';
import Hotel from '../../molecules/Hotel';

const HotelsCarousel: React.FC<HotelsCarouselProps> = ({ data }) => (
  <Styled.HotelsCarouselWrapper>
    <Slider {...settings}>
      {data.length &&
        data.map(({ key, ...restHotelProps }) => (
          <Styled.HotelsCarouselItem {...{ key }}>
            <Hotel {...{ ...restHotelProps }} />
          </Styled.HotelsCarouselItem>
        ))}
    </Slider>
  </Styled.HotelsCarouselWrapper>
);

export default HotelsCarousel;
