import styled, { css } from 'styled-components';

import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';
import { StyleHotelProps } from './types';

export const HotelTitle = styled(Typography)``;

export const HotelIcon = styled.div`
  margin-top: 3rem;
  font-size: 20rem;

  @media ${media.tablet} {
    margin-top: 6.25rem;
  }

  svg {
    display: flex;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
  }

  &,
  & > div,
  & > div > div {
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
    height: 20rem;
    max-width: 100%;
  }
`;

export const HotelPrice = styled(Typography)`
  margin-top: 2rem;

  @media ${media.tablet} {
    margin-top: 4rem;
  }
`;

export const HotelDescription = styled(Typography)`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const HotelAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

export const Hotel = styled.div<StyleHotelProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 2.5rem 2.5rem 5rem;
  background-color: ${({ theme }) => theme.colors.additional.accentGray3};

  @media ${media.phone} {
    padding: 2rem 2rem 5rem;
  }

  @media ${media.tablet} {
    padding: 5rem;
  }

  ${({ isDark }) =>
    isDark &&
    css`
      color: ${({ theme }) => theme.colors.neutral.neutral10};
      background-color: ${({ theme }) => theme.colors.additional.dark};
      ${HotelDescription} {
        color: ${({ theme }) => theme.colors.additional.accentGray3};
      }
    `};
`;
