import styled from 'styled-components';
import Slider from 'react-slick';

import media from '../../../common/MediaQueries';

export const HotelsCarouselWrapper = styled.div`
  .slick-dots {
    position: absolute;
    bottom: 2rem;
  }

  @media ${media.maxPhone} {
    .slick-slider:not([dir='ltr']) .slick-slide:not(:first-of-type) {
      display: none;
    }
    .slick-slider:not([dir='ltr']) .slick-slide:first-of-type {
      width: 100% !important;
      max-width: 100%;
    }
  }

  .slick-slide {
    display: block;
    height: inherit;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }
`;

export const HotelsCarousel = styled(Slider)`
  position: relative;
`;

export const HotelsCarouselItem = styled.div``;
