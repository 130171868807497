import React from 'react';
import { Settings } from 'react-slick';

import * as Styled from '../../atoms/Carousel/styles';

export const settings: Settings = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  appendDots: (dots: React.ReactNode) => (
    <Styled.CarouselPaginationDots>{dots}</Styled.CarouselPaginationDots>
  ),
  customPaging: () => <Styled.CarouselPagination />,
};
